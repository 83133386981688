<template>
  <div class="book-detail">
    <p class="title">ISO9000银行业实施指南</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书结合银行业的经营实际和ISO9001标准的要求，对银行业推行质量管理的推进过程进行探讨，让银行从业者可以由表及里、深入浅出的理解ISO9000精髓。</span
      >
    </div>
    <br />
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >中国标准出版社与我司继推出《ISO14001理论与实践丛书》后又共同策划了一套《ISO9001理论与实践丛书》，本书是其中之一。</span
      >
      <br />
      <span
        >21世纪的世界经济，将逐步步入“经济一体化，贸易自由化”的发展轨道。中国入世后，国内经济也将逐步融入“两化"之中，这意味着市场经济的竞争已经冲破国界。我国企业要想在新的经营环境中有效发展，必须重视质量管理。也就是说，质量已经成了21世纪企业发展的共同主题。国际标准化组织（ISO）所制定的质量管理体系标准（即ISO9000标准），无疑是各国企业质量管理求得认同的“基础语言"，因此，企业的产品或服务导入ISO9000标准已经成为国与国之间产品竞争与合作的基本条件。在世纪之交的2000年，中国农业银行湖南省分行把金融竞争的焦点放在服务创新上，率先在个人业务服务系统中导入ISO9000标准，并取得了积极成果，这是一种有益尝试，更是一种敢为人先的服务创新之举。我们结合银行业的经营实际，组织编写了《ISO9000：2000银行业实施指南》一书，是对银行业质量管理的探讨，更是对ISO9000标准渗透银行经营管理的总结。本书所涵盖的六章内容，既按银行业贯标的基本顺序加以组织，又兼顾了ISO9000标准的推进过程，能够让读者由表及里、浅入深出地理解ISO9000精髓。</span
      >
    </div>
  </div>
</template>
    
    <script>
export default {};
</script>
    
    <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>